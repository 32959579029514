<template>
    <div class="PersonEditRedirectView ViewWrapper">
        <clv-head-meta :title="$t('common_state_loading') + '...'"></clv-head-meta>
        <element-loading :active="true" :is-full-view="true" :lock-parent="true"></element-loading>
    </div>
</template>

<script>
/**
 * Person create/edit redirect view.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 26 June 2020
 */
export default {
    name: 'PersonEditRedirectView',
    mounted () {
        setTimeout(function () {
            this.redirect();
        }.bind(this), 250);
    },
    methods: {
        redirect () {
            let type = this.$route.params.type;
            if (type === undefined) type = null;
            let id = this.$route.params.id;
            if (id === undefined) id = null;

            if (type === 'new') {
                this.$router.replace({ name: 'person-edit' });
            } else if (type === 'edit') {
                if (id !== null) {
                    this.$router.replace({ name: 'person-edit', params: { id: id } });
                } else {
                    this.$router.replace({ name: 'people' });
                }
            } else if (type === 'view') {
                if (id !== null) {
                    this.$router.replace({ name: 'person', params: { id: id } });
                } else {
                    this.$router.replace({ name: 'people' });
                }
            } else {
                this.$router.replace({ name: 'people' });
            }
        }
    }
};
</script>
